import React from 'react';
import './App.css';
import TransactionHandler from './components/TransactionHandler';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, SolflareWalletAdapter, TorusWalletAdapter, LedgerWalletAdapter } from '@solana/wallet-adapter-wallets';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const App = () => {
    
    const wallets = [
        new PhantomWalletAdapter(),
        new SolflareWalletAdapter(),
        new TorusWalletAdapter(),
        new LedgerWalletAdapter()
    ];
    const endpoint = "https://side-yolo-pine.solana-mainnet.quiknode.pro/132ea46e472fd48bb360be830f8815c367051407";
    
    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <div className="App">
                        <h1>Burn Tokens</h1>
                        <WalletMultiButton />
                        <QueryClientProvider client={queryClient}>
                            <TransactionHandler />
                        </QueryClientProvider>
                    </div>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default App;
