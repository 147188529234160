import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { Transaction, TransactionInstruction, PublicKey, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID, createCloseAccountInstruction, createBurnInstruction } from '@solana/spl-token';
import { useQuery } from '@tanstack/react-query';

const MAX_INSTRUCTIONS_PER_TRANSACTION = 50;
const MAX_TRANSACTION_SIZE = 10;
const MAIN_TOKEN_LIST_URL = 'https://tokens.jup.ag/tokens?tags=verified,strict';
const FALLBACK_TOKEN_LIST_URL = 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@latest/src/tokens/solana.tokenlist.json';

const fetchTokenLists = async () => {
    const [mainResponse, fallbackResponse] = await Promise.all([
        axios.get(MAIN_TOKEN_LIST_URL),
        axios.get(FALLBACK_TOKEN_LIST_URL),
    ]);

    return {
        mainTokenList: mainResponse.data,
        fallbackTokenList: fallbackResponse.data.tokens,
    };
};

const fetchTokenPrices = async (mints) => {
    if (mints.length === 0) return {};

    const ids = mints.join(',');
    const url = `https://api.jup.ag/price/v2?ids=${ids}&vsToken=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v`;
    const response = await axios.get(url);

    const prices = {};
    mints.forEach((mint) => {
        const priceData = response.data?.data?.[mint]?.price;
        prices[mint] = priceData ? parseFloat(priceData) : "N/A";
    });

    return prices;
};

const TransactionHandler = () => {
    const { publicKey, connected, signAllTransactions } = useWallet();
    const { connection } = useConnection();

    const [transactions, setTransactions] = useState([]);
    const [burnableTokens, setBurnableTokens] = useState([]);
    const [emptyAccounts, setEmptyAccounts] = useState([]);
    const [selectedTokens, setSelectedTokens] = useState(new Set());
    const [transactionInfo, setTransactionInfo] = useState(null);
    const [error, setError] = useState(null);
    const [tokenPrices, setTokenPrices] = useState({});

    const { data: tokenLists, isError: tokenListError } = useQuery({
        queryKey: ['tokenLists'],
        queryFn: fetchTokenLists,
        onError: () => setError("Failed to fetch token lists"),
    });

    const fetchTokens = useCallback(async () => {
        if (publicKey && connected) {
            try {
                const response = await connection.getParsedTokenAccountsByOwner(publicKey, { programId: TOKEN_PROGRAM_ID });
                const accounts = response.value;
                const empty = [];
                const burnable = [];
                const mints = [];
    
                accounts.forEach(({ pubkey, account }) => {
                    const data = account.data.parsed.info;
                    if (data.tokenAmount.uiAmount > 0) {
                        burnable.push({
                            mint: new PublicKey(data.mint),
                            pubkey: new PublicKey(pubkey),
                            amount: data.tokenAmount.uiAmount,
                        });
                        mints.push(data.mint);
                    } else {
                        empty.push({
                            mint: new PublicKey(data.mint),
                            pubkey: new PublicKey(pubkey)
                        });
                    }
                });
    
                const tokensWithMetadata = burnable.map(token => {
                    const metadata = tokenLists?.mainTokenList.find(t => t.address === token.mint) ||
                                     tokenLists?.fallbackTokenList.find(t => t.address === token.mint);
                    return {
                        ...token,
                        name: metadata?.name || null,
                        symbol: metadata?.symbol || null,
                        logo: metadata?.logoURI || null,
                    };
                });
    
                const prices = await fetchTokenPrices(mints);
                setTokenPrices(prices);
                setBurnableTokens(tokensWithMetadata);
                setTransactions([]);
                setTransactionInfo(null);
                setEmptyAccounts(empty);
    
                return tokensWithMetadata;
            } catch (err) {
                console.error("Error fetching tokens:", err);
                setError("Failed to fetch tokens");
                return [];
            }
        } else {
            return [];
        }
    }, [publicKey, connected, connection, tokenLists]);

    useQuery({
        queryKey: ['tokens', connected],
        queryFn: fetchTokens,
        enabled: connected && !!publicKey,
        onError: () => setError("Failed to fetch tokens"),
    });

    useEffect(() => {
        if (connected && publicKey) {
            fetchTokens(); // Fetch tokens immediately on load
        }
    }, [connected, publicKey]);

    const handleTokenSelect = (token) => {
        const newSelected = new Set(selectedTokens);
        if (newSelected.has(token)) {
            newSelected.delete(token);
        } else {
            newSelected.add(token);
        }
        setSelectedTokens(newSelected);
    };

    const simulateAndExecuteTransactions = async (transactions) => {
        try {
            // for (let i = 0; i < transactions.length; i++) {
            //     const result = await connection.simulateTransaction(transactions[i]);
    
            //     // Check if the transaction simulation has any errors
            //     if (result.value.err) {
            //         console.error(`Transaction ${i + 1} simulation failed:`, result.value.err);
    
            //         // Parse out specific error details for better context
            //         let errorMessage;
            //         if (result.value.err.InstructionError) {
            //             const [instructionIndex, errorDetail] = result.value.err.InstructionError;
            //             errorMessage = `Instruction ${instructionIndex + 1} failed with error: ${JSON.stringify(errorDetail)}`;
                        
            //             // Provide more specific information for Custom errors
            //             if (errorDetail.Custom) {
            //                 if (errorDetail.Custom === 12) {
            //                     errorMessage += " (Insufficient funds or token balance)";
            //                 } else {
            //                     errorMessage += ` (Custom error code: ${errorDetail.Custom})`;
            //                 }
            //             }
            //         } else {
            //             errorMessage = typeof result.value.err === 'object'
            //                 ? JSON.stringify(result.value.err, null, 2)
            //                 : result.value.err.toString();
            //         }
    
            //         throw new Error(`Transaction ${i + 1} simulation failed: ${errorMessage}`);
            //     }
            // }
    
            // All transactions simulated successfully; proceed to sign and send them
            const signedTransactions = await signAllTransactions(transactions);
            for (const signedTransaction of signedTransactions) {
                await connection.sendRawTransaction(signedTransaction.serialize());
            }
            setTransactionInfo("Transactions executed successfully");
        } catch (err) {
            console.error("Transaction execution error:", err);
            setError(err.message);
        }
    };

    const closeEmptyAccounts = useCallback(async () => {
        if (!publicKey || !connected) {
            setError("Wallet not connected or missing public key.");
            return;
        }
    
        const emptyAccountTransactions = [];
        let currentTransaction = new Transaction();
        let totalServiceFee = 0;

        for (const token of emptyAccounts) {
            if (!token.pubkey || !publicKey) {
                console.warn("Token account or public key missing:", token);
                continue;
            }
    
            try {
                const closeInstruction = createCloseAccountInstruction(
                    token.pubkey,
                    publicKey,
                    publicKey,
                    publicKey,
                    TOKEN_PROGRAM_ID
                );

                console.log("adding instruction ", closeInstruction)
                currentTransaction.add(closeInstruction);
                totalServiceFee += 0.0002
    
                if (currentTransaction.instructions.length >= MAX_TRANSACTION_SIZE) {
                    const feeReceiver = new PublicKey("HcLRAUCCWnbWbQcrLX1QDV38E6kYeopXSdcu1tvD4pMK");
                    const totalServiceFeeLamports = Math.round(totalServiceFee * LAMPORTS_PER_SOL);
                    const transferInstruction = SystemProgram.transfer({
                        fromPubkey: publicKey,
                        toPubkey: feeReceiver,
                        lamports: totalServiceFeeLamports
                    });

                    currentTransaction.add(transferInstruction);

                    emptyAccountTransactions.push(currentTransaction);
                    currentTransaction = new Transaction();
                    totalServiceFee = 0;
                }
            } catch (err) {
                console.error("Error creating close account instruction:", err);
                setError("Failed to create transaction instructions.");
                return;
            }
        }
    
        if (currentTransaction.instructions.length > 0) {
            const feeReceiver = new PublicKey("HcLRAUCCWnbWbQcrLX1QDV38E6kYeopXSdcu1tvD4pMK");
            const totalServiceFeeLamports = Math.round(totalServiceFee * LAMPORTS_PER_SOL);
            const transferInstruction = SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: feeReceiver,
                lamports: totalServiceFeeLamports
            });

            currentTransaction.add(transferInstruction);
            emptyAccountTransactions.push(currentTransaction);
        }
    
        try {
            const { blockhash } = await connection.getLatestBlockhash();
            emptyAccountTransactions.forEach((tx) => {
                tx.recentBlockhash = blockhash;
                tx.feePayer = publicKey;
            });
    
            setTransactionInfo(`Simulating and executing ${emptyAccountTransactions.length} transactions...`);
            await simulateAndExecuteTransactions(emptyAccountTransactions);
        } catch (err) {
            console.error("Error executing transactions:", err);
            setError("Failed to execute transactions for closing empty accounts.");
        }
    }, [emptyAccounts, publicKey, connected, connection]);
    

    const burnSelectedTokens = useCallback(async () => {
        if (!publicKey || !connected || selectedTokens.size === 0) return;
    
        const burnTransactions = [];
        let currentTransaction = new Transaction();
        let totalServiceFee = 0; // Track the total service fee per transaction
    
        burnableTokens.forEach((token) => {
            if (selectedTokens.has(token)) {
                try {
                    const burnInstruction = createBurnInstruction(
                        token.pubkey,
                        token.mint,
                        publicKey,
                        token.amount * 1e6,
                        [],
                        TOKEN_PROGRAM_ID
                    );
                    const closeInstruction = createCloseAccountInstruction(
                        token.pubkey,
                        publicKey,
                        publicKey,
                        TOKEN_PROGRAM_ID
                    );
    
                    // Add both burn and close instructions
                    currentTransaction.add(burnInstruction, closeInstruction);
    
                    // Add service fee of 0.0004 for each token (0.0002 per burn and 0.0002 per close)
                    totalServiceFee += 0.0004;
    
                    if (currentTransaction.instructions.length >= MAX_INSTRUCTIONS_PER_TRANSACTION) {
                        // Add the transfer fee instruction at the end of each transaction
                        const feeReceiver = new PublicKey("HcLRAUCCWnbWbQcrLX1QDV38E6kYeopXSdcu1tvD4pMK");
                        const totalServiceFeeLamports = Math.round(totalServiceFee * LAMPORTS_PER_SOL);
                        const transferInstruction = SystemProgram.transfer({
                            fromPubkey: publicKey,
                            toPubkey: feeReceiver,
                            lamports: totalServiceFeeLamports,
                        });
                        
                        currentTransaction.add(transferInstruction);
    
                        // Push the current transaction to the array and reset for a new one
                        burnTransactions.push(currentTransaction);
                        currentTransaction = new Transaction();
                        totalServiceFee = 0;
                    }
                } catch (err) {
                    console.error("Error creating burn or close instruction:", err);
                    setError("Failed to create transaction instructions for burning tokens.");
                    return;
                }
            }
        });
    
        // Handle any remaining instructions in the last transaction
        if (currentTransaction.instructions.length > 0) {
            const feeReceiver = new PublicKey("HcLRAUCCWnbWbQcrLX1QDV38E6kYeopXSdcu1tvD4pMK");
            const totalServiceFeeLamports = Math.round(totalServiceFee * LAMPORTS_PER_SOL);
            const transferInstruction = SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: feeReceiver,
                lamports: totalServiceFeeLamports,
            });
    
            currentTransaction.add(transferInstruction);
            burnTransactions.push(currentTransaction);
        }
    
        try {
            const { blockhash } = await connection.getLatestBlockhash();
            burnTransactions.forEach((tx) => {
                tx.recentBlockhash = blockhash;
                tx.feePayer = publicKey;
            });
    
            setTransactionInfo(`Simulating and executing ${burnTransactions.length} burn transactions...`);
            await simulateAndExecuteTransactions(burnTransactions);
        } catch (err) {
            console.error("Error burning tokens:", err);
            setError("Failed to burn selected tokens");
        }
    }, [burnableTokens, publicKey, connected, selectedTokens, connection]);
    

    return (
        <div>
            {transactionInfo && <p>{transactionInfo}</p>}
            <button onClick={closeEmptyAccounts}>Close Empty Accounts</button>
            <h3>Burnable Tokens:</h3>
            <ul>
                {burnableTokens.map((token) => (
                    <li key={token.pubkey.toBase58()}>
                        <input
                            type="checkbox"
                            checked={selectedTokens.has(token)}
                            onChange={() => handleTokenSelect(token)}
                        />
                        {token.logo && (
                            <img
                                src={token.logo}
                                alt={token.name || token.mint.toBase58()}
                                style={{ width: '24px', height: '24px', marginRight: '8px' }}
                            />
                        )}
                        {token.name || token.mint.toBase58()} - {token.amount} tokens
                        {typeof tokenPrices[token.mint.toBase58()] === 'number' ? (
                            <span style={{ marginLeft: '8px' }}>
                                Price: ${(tokenPrices[token.mint.toBase58()] * token.amount).toFixed(6)}
                            </span>
                        ) : (
                            <span style={{ marginLeft: '8px' }}>
                                Price: N/A
                            </span>
                        )}
                    </li>
                ))}
            </ul>
            <button onClick={burnSelectedTokens} disabled={selectedTokens.size === 0}>
                Burn Selected
            </button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default TransactionHandler;
